export const operationManageRoute = [
   {
      path: '/operationManage',
      meta: { title: '运营管理', keepAlive: true, icon: 'shopping' },
      redirect: '/shopkeeperManage/userList',
      component: () => import('@/components/RouteView'),
      children: [
         {
            path: '/operationManage/main',
            name: 'OperationManageMain',
            component: () => import('@/views/operationManage/messageCenter/main'),
            meta: { title: '消息中心管理', keepAlive: true, icon: 'unordered-list', auth: 'messageCenter:menu'},
         },
         {
            path: '/operationManage/systemMessage',
            name: 'OperationManageSystem',
            component: () => import('@/views/operationManage/messageCenter/systemMessage'),
            meta: { title: '系统消息管理', keepAlive: true, icon: 'unordered-list', auth: 'messageCenter:systemMsg'},
            hidden: true
         },
         {
            path: '/operationManage/activityMessage',
            name: 'OperationManageActivity',
            component: () => import('@/views/operationManage/messageCenter/activityMessage'),
            meta: { title: '活动通知管理', keepAlive: true, icon: 'unordered-list', auth: 'messageCenter:activityMsg'},
            hidden: true
         },
         {
            path: '/operationManage/liveManage',
            name: 'OperationManageLive',
            component: () => import('@/views/operationManage/live/index'),
            meta: { title: '直播管理', keepAlive: true, icon: 'unordered-list', auth: 'liveManage:menu'},
         },
         {
            path: '/operationManage/liveManageData',
            name: 'OperationManageLiveManageData',
            component: () => import('@/views/operationManage/live/data'),
            meta: { title: '直播管理-数据', keepAlive: false, icon: 'unordered-list', auth: 'liveManage:menu'},
            hidden: true
         }
      ]
   }
]