import Vue from 'vue'
import store from './store'

Vue.directive('auth',{
   bind: (el,binding) => {
      const auth = binding.value
      if(!auth) return
      if (!store.state.permissionList.includes(auth)) {
         el.style.display = 'none'
      }
   }
})

Vue.directive('drag-modal', (el, bindings, vnode) => {
   Vue.nextTick(() => {
     let { visible, destroyOnClose } = vnode.componentInstance
     if (!visible) return
     let modal = el.getElementsByClassName('ant-modal')[0]
     let header = el.getElementsByClassName('ant-modal-header')[0]
     let left = 0
     let top = 0
     if (!destroyOnClose) {  
       left = modal.left || 0
       top = modal.top || 0
     }
     top = top || modal.offsetTop
     header.onmousedown = e => {
       let startX = e.clientX;
       let startY = e.clientY;
       header.left = header.offsetLeft;
       header.top = header.offsetTop;
       el.onmousemove = event => {
         let endX = event.clientX;
         let endY = event.clientY;
         modal.left = header.left + (endX - startX) + left;
         modal.top = header.top + (endY - startY) + top;
         modal.style.left = modal.left + 'px'
         modal.style.top = modal.top + 'px'
       }
       el.onmouseup = event => {
         left = modal.left
         top = modal.top
         el.onmousemove = null;
         el.onmouseup = null; 
         header.releaseCapture && header.releaseCapture();
       }
       header.setCapture && header.setCapture();
     }  
   })
 })