export const shopkeeperManageRoute = [{
   path: '/shopkeeperManage',
   meta: { title: '店主管理', keepAlive: true, icon: 'team' },
   redirect: '/shopkeeperManage/userList',
   component: () => import('@/components/RouteView'),
   children: [
     {
       path: '/shopkeeperManage/userList',
       name: 'UserList',
       component: () => import('@/views/shopkeeperManage/userList'),
       meta: { title: '用户列表', keepAlive: true, icon: 'unordered-list', auth: 'userList:list' },
     },
     {
      path: '/shopkeeperManage/userList_new',
      name: 'UserListNew',
      component: () => import('@/views/shopkeeperManage/userList_new'),
      meta: { title: '用户列表(新)', keepAlive: true, icon: 'unordered-list', auth: 'userListNew:list' },
    },
     {
       path: '/shopkeeperManage/teamList',
       name: 'TeamList',
       component: () => import('@/views/shopkeeperManage/teamList'),
       meta:{title:'团队列表',keepAlive:true,icon:'unordered-list',auth:'teamList:teamMember'},
     },
     {
       path: '/shopkeeperManage/enterpriseList',
       name: 'EnterpriseList',
       component: () => import('@/views/shopkeeperManage/enterpriseList'),
       meta:{title:'企业账号列表',keepAlive:true,icon:'unordered-list',auth:'enterpriseList:list'},
     }
   ]
 }]