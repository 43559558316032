export const integratedManageRoute = [{
   path: '/integratedManage',
   meta: { title: '综合管理', keepAlive: true, icon: 'bank' },
   redirect: '/integratedManage/downloadFileManage',
   component: () => import('@/components/RouteView'),
   children: [
     {
       path: '/integratedManage/downloadFileManage',
       name: 'downloadFileManage',
       component: () => import('@/views/integratedManage/downloadFileManage'),
       meta: { title: '文件列表', keepAlive: true, icon: 'unordered-list', auth: 'downloadFileManage:list' },
     }
   ]
 }]