import { request } from './request.js'

const lecturer = {
  // 讲师动态
  lecturerRecord(params) {
    return request.post('/api/teacher/record', params)
  },
  // 讲师列表
  lecturerList(params) {
    return request.post('/api/teacher/list', params)
  },
  meetingCode(params) {
    return request.post('/api/meetingCode/ranCode', params)
  },
  addLecturer(params) {
    return request.post('/api/teacher/add', params)
  },
  editLecturer(params) {
    return request.post('/api/teacher/update', params)
  },
  revokeLecturer(params) {
    return request.post('/api/teacher/revoke', params)
  },
  activeLecturer(params) {
    return request.post('/api/teacher/active', params)
  },
  exportOrderList: '/ar-advance/xmallYx/teachersList/expJob',

}

export default {
  ...lecturer,
}