
import { request } from './request.js'

let downloadFileManage = {
  //文件管理任务列表接口
  downloadTaskList(params) {
    return request.post('/ar-advance/admin/product/task/list', params)
  },
  //获取单个异步任务执行进度接口
  downloadExecRatio(params) {
    return request.post('/ar-advance/admin/product/task/execRatio', params)
  },
  // 删除导出记录
  deleteFail(params) {
    return request.post('/ar-advance/admin/product/task/deleteFail', params)
  },
  // 重试导出任务
  retryTask(params) {
    return request.post('/ar-advance/admin/task/retry', params)
  },
}

export default {
  ...downloadFileManage
}