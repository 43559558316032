import { request } from './request'

// 消息中心
const messageCenter = {
   // 消息列表
   userMsgList (params) {
      return request.post('/api/userMsg/list', params)
   },

   //添加、编辑信息
   userMsgOperate (params) {
      return request.post('/api/userMsg/operate', params)
   },

   // 修改消息状态
   userMsgUpdateStatus (params) {
      return request.post('/api/userMsg/updateStatus', params)
   }
}

const liveManage = {
   // 消息列表
   liveManageList (params) {
      return request.post('/api/live/list', params)
   },
   editManage (params) {
      return request.post('/api/live/edit', params)
   },
   shareCount (params) {
      return request.post('/api/live/shareCount', params)
   }
}

export default {
   ...messageCenter,
   ...liveManage
}