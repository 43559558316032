import pms from './pms'
import marketManage from './marketManage'
import shopkeeperManage from './shopkeeperManage'
import integratedManage from './integratedManage'
import lecturer from './lecturer'
import chartsManage from './chartsManage'
import operationManage from './operationManage'
import util from './util'
export default {
   pms,
   marketManage,
   shopkeeperManage,
   integratedManage,
   lecturer,
   chartsManage,
   operationManage,
   util
}