import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less';
import Cookies from 'js-cookie'
import router from './router'
import store from './store'
import './style/global.less'
import 'moment/locale/zh-cn';
import './directive'
import { ssoLogin } from './ssoLogin'
import api from './api'
import Message from 'ant-design-vue/es/message'

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)
Viewer.setDefaults({
  title: false
})


Vue.use(Antd);
Vue.config.productionTip = false

Vue.prototype.successCode = '00000' //响应成功码'

const loadUserInfo = () => {
  return new Promise((resolve, reject) => {
    let url = new URL(window.location.href)
    let gwToken = url.searchParams.get('gwToken') || Cookies.get('gwToken')
    if (gwToken) {
      store.commit('setGwToken', gwToken)
      Cookies.set('gwToken', gwToken)
    } else {
      return ssoLogin()
    }
    api.pms.loginUserInfo().then(userInfo => {
      if (userInfo.code === 10000) {
        store.commit('setUserInfo', userInfo.data)
        Cookies.set("account", userInfo.data.account);
        Cookies.set("id", userInfo.data.id);
        Cookies.set("accountName", userInfo.data.accountName);
        api.pms.userPermission().then(permission => {
          if (permission.code === 10000) {
            store.commit('setPermissionList', permission.data)
            resolve()
          } else {
            reject()
            Message.error(permission.msg)
          }
        })
      } else {
        reject()
        return ssoLogin()
      }
    })
  })
}

loadUserInfo().finally(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})







