<template>
  <div class="open-tags-list" ref='container' >
   <div class="wrapper" ref="wrapper" @mousewheel.prevent="handleScroll">
      <div class="tag" ref='tagList' v-for="page in openPageList" :key='page.name' @click="toPage(page.name,page)">
         <div class="dot" :style="{backgroundColor: page.name === currentPageName ? '#1890ff' : '#f0f2f5'}"></div>
         <span>{{page.title}}</span>
         <a-icon @click="closePage(page.name, $event)" type="close" v-if="openPageList.length !== 1"/>
      </div>
   </div>
   
  </div>
</template>

<script>
export default {
   name: 'OpenTagsList',
   data () {
      return {
         currentPageName: ''
      }
   },
   computed: {
      openPageList () {
         return this.$store.state.openPageList
      },
   },
   watch: {
      '$route': {
         immediate: true,
         handler (val) {
            this.currentPageName = val.name
            if (this.currentPageName === 'NoAuth') return
            this.$nextTick(() => {
               this.moveToViews()
            })
         }
      }
   },
   methods: {
      toPage (name,obj) {
         if (name === this.currentPageName) {
            return
         }
         const query = obj.query ? obj.query : undefined
         this.$router.push({
            name,
            query
         })
      },
      closePage (name, e) {
         e.stopPropagation()
         this.$store.commit('deletePage', name)
         if (name === this.currentPageName) {
            this.$router.back()
         }
      },
      moveToViews () {
         let index = this.openPageList.findIndex(page => page.name === this.currentPageName)
         let container = this.$refs.container
         let wrapper = this.$refs.wrapper
         let tag = this.$refs.tagList[index]
         if (wrapper.scrollLeft + container.offsetWidth < tag.offsetLeft + tag.offsetWidth) {
            wrapper.scrollTo({
               left: tag.offsetLeft + tag.offsetWidth - container.offsetWidth,
               behavior: 'smooth'
            })
         } else if (wrapper.scrollLeft > tag.offsetLeft - container.offsetLeft - tag.offsetWidth) {
            wrapper.scrollTo({
               left: tag.offsetLeft - container.offsetLeft - tag.offsetWidth * 2,
               behavior: 'smooth'
            })
         }
         
      },
      handleScroll (e) {
         let step = e.deltaY > 0 ? 50 : -50
         let wrapper = this.$refs.wrapper
         wrapper.scrollTo({
            left: wrapper.scrollLeft + step,
            behavior: 'smooth'
         })
      }
   }
}
</script>

<style lang='less' scoped>
.open-tags-list {
   width: 100%;
   margin-bottom: 8px;
   transition: all .3s ease;

   ::-webkit-scrollbar {
      display: none;
   }
   .wrapper {
      display: flex;
      // position: absolute;
      transition: all .3s ease;
      overflow: auto;
   }
   .tag {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      padding: 4px 8px;
      margin-right: 8px;
      flex-shrink: 0;
      cursor: pointer;
      border-radius: 4px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
      .dot {
         width: 14px;
         height: 14px;
         border-radius: 50%;
      }
      > span {
         margin: 0 4px;
         user-select: none;
      }
   }
}
</style>