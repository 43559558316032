<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <keep-alive >
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
  </a-config-provider>
</template>
<script>
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
  export default {
    data() {
      return {
        locale: zhCN,
      };
    },
  }
</script>
<style lang="less">
#app {
  height: 100%;
  font-weight: 500;
  overflow: auto;
  font-family: PingFangSC-Medium, PingFang SC;
}
</style>
