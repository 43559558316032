import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    gwToken:'',
    userInfo:{},
    permissionList:[],
    openPageList: sessionStorage.getItem('openPageList') ? JSON.parse(sessionStorage.getItem('openPageList')) : []
  },
  mutations: {
    setGwToken(state,gwToken) {
      state.gwToken = gwToken
    },
    setUserInfo(state,userInfo) {
      state.userInfo = userInfo
    },
    setPermissionList(state,permissionList) {
      state.permissionList = permissionList
    },
    setOpenPageList (state, page) {
      let {name, meta:{title},query} = page
      let excludesList = ['NoAuth']
      if (excludesList.includes(name)) return
      // 如果一样，就更新query参数
      const havaValue= state.openPageList.find(item => {
        if(item.name === name){
          item.query = query
          return true
        } 
      })
      if(!havaValue){
        state.openPageList.push({
          name,
          title,
          query
        })  
      } 
      sessionStorage.setItem('openPageList', JSON.stringify(state.openPageList))
    },
    deletePage (state, name) {
      state.openPageList = state.openPageList.filter(item => item.name !== name)
      sessionStorage.setItem('openPageList', JSON.stringify(state.openPageList))
    },
    clearPage (state) {
      state.openPageList = []
      sessionStorage.setItem('openPageList', JSON.stringify(state.openPageList))
    }
  }
})
