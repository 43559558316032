export const authRoute = [
   {
      path: '/noAuth',
      component: () => import('@/views/noAuth.vue'),
      hidden: true,//设为true不在菜单显示
      name: 'NoAuth'
    },
    {
      path: '/:\w(.+)',
      component: () => import('@/views/noAuth.vue'),
      hidden: true,//设为true不在菜单显示
      name: 'NoAuth'
    }
]