export const lecturerManageRoute = [{
   path: '/lecturerManage',
   meta: { title: '讲师管理', keepAlive: true, icon: 'user' },
   component: () => import('@/components/RouteView'),
   redirect: '/lecturerManage/lecturerList',
   children: [
     {
       path: '/lecturerManage/lecturerList',
       name: 'lecturerList',
       component: () => import('@/views/lecturerManage/lecturerList.vue'),
       meta: { title: '讲师列表', keepAlive: true, icon: 'unordered-list', auth: 'lecturerList:list' },
     },
     {
       path: '/lecturerManage/lecturerTrend',
       name: 'lecturerTrend',
       component: () => import('@/views/lecturerManage/lecturerTrend.vue'),
       meta: { title: '讲师动态', keepAlive: true, icon: 'unordered-list', auth: 'lecturerTrend:list' },
     }
   ]
 }]