import { request } from './request.js'

const orderList = {
   //订单列表
   orderList (params) {
      return request.post('/api/smallBonus/orderList', params)
   },
   //订单列表导出
   exportOrderList : '/ar-advance/xmallYx/orderList/expJob',

   //查看订单历史时间
   orderTime(params) {
      return request.post('/api/smallBonus/orderTime', params)
   },


   /* 
      以下是新版接口
   */
   // 订单列表(新)
   orderListNew (params) {
      return request.post('/api/smallBonus/orderListNew', params)
   },

   // 订单列表-查看奖励明细
   getAllRewardList (params) {
      return request.post('/api/rewards/getAllRewardList', params)
   },

   // 订单列表导出
   exportOrderListNew : '/ar-advance/xmallYx/orderListNew/expJob',


}

const rewardList = {
   // 奖励列表
   rewardList (params) {
      return request.post('/api/smallBonus/bonusList',params)
   },
   // 奖励列表(二级页面)
   rewardSecondList (params) {
      return request.post('/api/smallBonus/rewardsList',params)
   },
    //奖励列表导出
   exportRewardList : '/ar-advance/xmallYx/bonusList/expJob'
}

//积分奖励列表
const rewardScoreList = {
   jifenList (params) {
      return request.post('/api/rewards/jifen', params)
   },
   // 奖励积分列表导出
   jifenExportUrl: '/ar-advance/xmallYx/jifenList/expJob',

   //积分奖励查询统计
   jifenStatistics (params) {
      return request.post('/api/rewards/jifenStatistics', params)
   }
}

//小汇豆奖励列表
const beansList = {
   beansList(params) {
      return request.post('/api/rewards/beans', params)
   },
   // 小汇豆奖励列表导出
   beansExportUrl: '/ar-advance/xmallYx/beansList/expJob',

   //小汇豆查询统计
   beansStatistics (params) {
      return request.post('/api/rewards/beansStatistics', params)
   }
}
const withdrawnList = {
   // 撤回列表
   withdrawList (params) {
      return request.post('/api/smallBonus/withdrawList', params)
   },

   // 撤回列表-查看
   returnList (params) {
      return request.post('/api/smallBonus/returnList', params)
   },
   // 撤回奖励 (订单退款并撤回订单奖励)
   withdrawReward (params) {
      return request.post('/api/smallBonus/withdraw', params)
   }
}

//积分/小汇豆充值列表
const rechargeList = {

   //积分/小汇豆充值列表
   operateBonusList (params) {
      return request.post('/api/admin/operateBonusList', params)
   },

   //积分/小汇豆充值
   operateBonus (params) {
      return request.post('/api/admin/operateBonus', params)
   },

   // 导出
   exportBonusList: '/ar-advance/xmallYx/adminBonusList/expJob'
}


//直播分享列表
const liveShareList = {

   shareList (params) {
      return request.post('/api/live/shareList', params)
   },
   //奖励列表导出
   exportShareList : '/ar-advance/xmallYx/shareList/expJob'

}

// 奖励金列表
const newRewardList = {
   getNewRewardList (params) {
      return request.post('/api/rewards/newRewardList', params)
   },
}

// 业绩奖励列表
const performanceRewardList = {
   performanceRewardList (params) {
      return request.post('/api/rewards/performanceRewardList', params)
   },
}

// 出单奖励列表
const pushRewardList = {
   pushRewardList (params) {
      return request.post('/api/rewards/pushRewardList', params)
   },
}

export default {
   ...orderList,
   ...rewardList,
   ...rewardScoreList,
   ...beansList,
   ...withdrawnList,
   ...rechargeList,
   ...liveShareList,
   ...newRewardList,
   ...performanceRewardList,
   ...pushRewardList
}
