import { request, mallRequset } from "./request"

export default {
   // 上传文件
   uploadFile (params) {
      return request.post('/api/upload/oss', params)
   },

   //获取省市(二级)
   getRegion (params = {}) {
      return mallRequset.post('/mall/adminGateway', { ...params, function: 'xmallApi.api.region.export-two-level' })
   }
}