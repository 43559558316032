<template>
   <div class="container">
      <a-layout id="components-layout-demo-custom-trigger">
         <a-layout-sider v-model="collapsed" :trigger="null" collapsible :width='210'>
            <div class="logo">
               <img v-if="!collapsed" src="./../../public/yameilogo.png">
               <img v-else src="./../../public/logo-min.png">
            </div>
            <side-menu :menu='menus' class="side-menu"/>
         </a-layout-sider>
         <a-layout>
            <a-layout-header class="layout-header">
               <a-icon
                  class="trigger"
                  :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                  @click="() => (collapsed = !collapsed)"
               />
                 <a-dropdown  class="user" placement='bottomRight'>
                  <span style="font-weight:550">
                     {{$store.state.userInfo.accountName}}
                      <a-icon type="down" />
                  </span>
                  <a-menu slot="overlay">
                     <a-menu-item>
                        <span @click="logout"><a-icon type="logout" />退出登录</span>
                     </a-menu-item>
                  </a-menu>
               </a-dropdown>
            </a-layout-header>
            
            <a-layout-content
               :style="{  padding: '12px 24px 24px', background: '#f0f2f5' }"
               >
                  <OpenTagsList />
                  <router-view/>
            </a-layout-content>
         </a-layout>
      </a-layout>
   </div>
</template>

<script>
import SideMenu from '@/components/sideMenu'
import OpenTagsList from '@/components/OpenTagsList'
import {routes} from '@/router'
import api from '@/api'
export default {
   name:'Home',
   components:{SideMenu, OpenTagsList},
   data () {
      return {
         collapsed:false,
         routes,
         menus:[],
         authArr:this.$store.state.permissionList
      }
   },
   created () {
      this.menus = this.filterEmptyMenu(this.checkAuth(this.routes.find(item => item.path === '/').children))
   },
   methods: {
      checkAuth (data) {
         // 过滤没有权限的菜单
         for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if(element.children && element.children.length) {
               this.checkAuth(element.children)
            }
            if (element.meta && element.meta.auth) {
               let auth = element.meta.auth
               if (!this.authArr.includes(auth)) {
                  data.splice(i,1)
                  i--
               }
            }
         }
         return data
      },
      filterEmptyMenu (data) {
         // 过滤没有子菜单的菜单
         for (let i = 0; i < data.length; i++) {
            const ele = data[i]
            if (ele.children && ele.children.length !== 0) {
               ele.children = this.filterEmptyMenu(ele.children)
            }
            if (ele.children && ele.children.filter(i => !i.hidden).length === 0) {
               data.splice(i,1)
               i--
            }
         }

         return data
      },
      logout () {
         const _this = this
         _this.$confirm({
            content: '确定要退出登录吗?',
            onOk() {
               api.pms.logout().then(res => {
                  if (res.code === 10000) {
                     _this.$store.commit('clearPage')
                     window.location.replace(window.location.origin)
                  } else {
                     _this.$message.error(res.msg)
                  }
               })
            },
         });
       }
   }
}
</script>

<style scoped lang='less'>
.container,#components-layout-demo-custom-trigger{
   height: 100%;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 56px;
  padding-left: 24px;
  line-height: 54px;
  cursor: pointer;
  > img {
   height:65%
  }
}
.side-menu {
   height: calc(100% - 65px);
   overflow: auto;
     &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
    background: #999;
    &:hover{
      background: #535353;
    }
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
    background: #000c17;
  }
}
.layout-header{
   background: #fff;
   padding: 0;
   display: flex;
   justify-content: space-between;
   .user{
      padding: 0 24px;
      cursor: pointer;
      height: 100%;
      box-sizing: border-box;
   }
}
</style>