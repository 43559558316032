import axios from 'axios'
import store from './../store'
import notification from 'ant-design-vue/es/notification'
import {encrypt} from '../libs/jiami'
import qs from 'qs'
const pmsRequset  = axios.create({
   timeout: 60000,
   headers: {
     'Content-Type': 'application/json;charset=UTF-8',
     'X-Requested-With': 'XMLHttpRequest',
     'yame-source': 'xmall-youxuan-admin'
   },
   withCredentials: true
})


const mallRequset  = axios.create({
   timeout: 60000,
   headers: {
     'Content-Type': 'application/x-www-form-urlencoded',
     'X-Requested-With': 'XMLHttpRequest',
     'yame-source': 'xmall-youxuan-admin'
   },
   withCredentials: true
})


const request  = axios.create({
   baseURL:'/xmallYouxuan',
   // baseURL:'http://10.1.140.99:15000',
   timeout: 60000,
   headers: {
     'Content-Type': 'application/json;charset=UTF-8',
     'X-Requested-With': 'XMLHttpRequest',
     'yame-source': 'xmall-youxuan-admin'
   },
   withCredentials: true
})

const rewardRequest  = axios.create({
   baseURL:'/sky-promotion-reward-admin-ui',
   timeout: 60000,
   headers: {
     'Content-Type': 'application/json;charset=UTF-8',
     'X-Requested-With': 'XMLHttpRequest',
     'yame-source': 'xmall-youxuan-admin'
   },
   withCredentials: true
})


pmsRequset.interceptors.request.use(config => {
   const gwToken = store.state.gwToken
   if (gwToken) {
      config.headers.GWTOKEN = gwToken
   }
   return config
})
pmsRequset.interceptors.response.use(response => {
   if (response.data.code == 10104) {//用户未登录
      window.location.reload()
   }
   return response.data
})

request.interceptors.request.use(config => {
   const gwToken = store.state.gwToken
   if (gwToken) {
      config.headers.GWTOKEN = gwToken
   }
   if(config.url && config.url.indexOf("ar-advance") >= 0){
      config.baseURL = "/"
   }
   return config
},err => {
   notification.error({key: 'error', message: '发生错误'})
})
request.interceptors.response.use(response => {
   if (response.data.code == 10104) {//用户未登录
      window.location.reload()
   }
   return response.data
},err => {
   notification.error({key: 'error', message: '发生错误'})
})


rewardRequest.interceptors.request.use(config => {
   const gwToken = store.state.gwToken
   if (gwToken) {
      config.headers.GWTOKEN = gwToken
   }
   if(config.url && config.url.indexOf("ar-advance") >= 0){
      config.baseURL = "/"
   }
   return config
},err => {
   notification.error({key: 'error', message: '发生错误'})
})
rewardRequest.interceptors.response.use(response => {
   if (response.data.code == 10104) {//用户未登录
      window.location.reload()
   }
   return response.data
},err => {
   notification.error({key: 'error', message: '发生错误'})
})

mallRequset.interceptors.request.use(config => {
   const gwToken = store.state.gwToken
   if (gwToken) {
      config.headers.GWTOKEN = gwToken
      config.headers['function'] = config.data.function
   }
   config.data = encrypt(config.data)
   config.data = qs.stringify(config.data)
   return config
},err => {
   notification.error({key: 'error', message: '发生错误'})
})
mallRequset.interceptors.response.use(response => {
   // if (response.data.code == 10104) {//用户未登录
   //    window.location.reload()
   // }
   return response.data
},err => {
   const obj = JSON.parse(JSON.stringify(err))
   if(obj && obj.message && obj.message.includes('502')){
      notification.error({key: 'error', message: '发生错误'})
   }
   
})


function stringify(params){
   let search = []
   for(let key in params){
      let item = [key,params[key]]
      search.push(item.join('='))
   }
   return search.join('&')
}


export {
   pmsRequset,
   request,
   rewardRequest,
   mallRequset
}