export const chartManageRoute = [{
   path: '/chartsManage',
   meta: { title: '数据看板', keepAlive: true, icon: 'bar-chart' },
   redirect: '/chartsManage/indexCharts',
   component: () => import('@/components/RouteView'),
   children: [
     {
       path: '/chartsManage/indexCharts',
       name: 'IndexCharts',
       component: () => import('@/views/chartsManage/indexCharts'),
       meta: { title: 'GMV图表', keepAlive: false, icon: 'unordered-list', auth: 'chartsList:gmvmenu' },
     },
   ]
 }]