export const marketManageRoute = [{
   path: '/marketManage',
   meta: { title: '营销管理', keepAlive: true, icon: 'shopping' },
   redirect: '/marketManage/orderList',
   component: () => import('@/components/RouteView'),
   children: [
     {
       path: '/marketManage/orderList',
       name: 'OrderList',
       component: () => import('@/views/marketManage/orderList'),
       meta: { title: '订单列表', keepAlive: true, icon: 'unordered-list', auth: 'orderList:list' },
     },
     /* 
       2022/1/12迭代 隐藏奖励列表
       http://prototype.ym/xiaohuiyouxuan1.10/#g=1&p=%E9%80%BB%E8%BE%91%E4%BC%98%E5%8C%96
     */
     /* {
       path:'/marketManage/rewardList',
       name:'RewardList',
       component: () => import('@/views/marketManage/rewardList'),
       meta:{title:'奖励列表',keepAlive:true,icon:'unordered-list',auth:'rewardList:list'},
     } */

    {
      path: '/marketManage/orderList_new',
      name: 'OrderListNew',
      component: () => import('@/views/marketManage/orderList_new'),
      meta: { title: '订单列表(新)', keepAlive: true, icon: 'unordered-list', auth: 'orderListNew:list' },
    },
    {
      path: '/marketManage/newRewardList',
      name: 'NewRewardList',
      component: () => import('@/views/marketManage/newRewardList'),
      meta: { title: '奖励金列表', keepAlive: true, icon: 'unordered-list', auth: 'newRewardList:list' },
    },
    {
      path: '/marketManage/performanceRewardList',
      name: 'PerformanceRewardList',
      component: () => import('@/views/marketManage/performanceRewardList'),
      meta: { title: '业绩奖励列表', keepAlive: true, icon: 'unordered-list', auth: 'performanceRewardList:list' },
    },
    {
      path: '/marketManage/pushRewardList',
      name: 'PushRewardList',
      component: () => import('@/views/marketManage/pushRewardList'),
      meta: { title: '出单奖励列表', keepAlive: true, icon: 'unordered-list', auth: 'pushRewardList:list' },
    },
     {
       path: '/marketManage/rewardScoreList',
       name: 'RewardScoreList',
       component: () => import('@/views/marketManage/rewardScoreList'),
       meta: { title: '积分奖励列表', keepAlive: true, icon: 'unordered-list', auth: 'jifenList:list' },
     },
     {
       path: '/marketManage/rewardBeansList',
       name: 'RewardBeansList',
       component: () => import('@/views/marketManage/rewardBeansList'),
       meta: { title: '小汇豆奖励列表', keepAlive: true, icon: 'unordered-list', auth: 'beansList:list' },
     },
     {
       path: '/marketManage/withdrawnList',
       name: 'WithdrawnList',
       component: () => import('@/views/marketManage/withdrawnList'),
       meta: { title: '撤回列表', keepAlive: true, icon: 'unordered-list', auth: 'withdrawnList:list' },
     },
     {
       path: '/marketManage/withdrawnReward',
       name: 'WithdrawnReward',
       hidden: true,
       component: () => import('@/views/marketManage/withdrawnReward'),
       meta: { title: '撤回订单奖励', icon: 'unordered-list', auth: 'withdrawnList:withdrawReward', keepAlive: true },
     },
     {
       path: '/marketManage/rechargeList',
       name: 'rechargeList',
       component: () => import('@/views/marketManage/rechargeList'),
       meta: { title: '积分/小汇豆充值列表', keepAlive: true, icon: 'unordered-list', auth:'operateBonus:list'}
     },
     {
       path: '/marketManage/rechargeOptions',
       name: 'rechargeOptions',
       hidden: true,
       component: () => import('@/views/marketManage/rechargeOptions'),
       meta: { title: '充值/扣减', keepAlive: true, icon: 'unordered-list', auth:'operateBonus:operate'}
     },
     {
        path: '/operationManage/liveShareList',
        name: 'OperationManageLiveShare',
        component: () => import('@/views/operationManage/liveShare/index'),
        meta: { title: '直播分享列表', keepAlive: true, icon: 'unordered-list', auth: 'liveShareList:menu'},
     }
   ]
 }]