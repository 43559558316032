import {request} from './request.js'

const userList = {
   userList (params) {
      return request.post('/api/smallBonus/userList',params)
   },

   //修改用户node状态  (解绑强关系)
   updateNodeStatus (params) {
      return request.post('/api/user/updateNodeStatus', params)
   },

   //查看上级变更历史
   userParentNodeChangeRecord (params) {
      return request.post('/api/user/userParentNodeChangeRecord', params)
   },

   // 用户列表导出
   exportUserList: '/ar-advance/xmallYx/userList/expJob'
}

const userListNew = {
   userListNew (params) {
      return request.post('/api/smallBonus/userListNew',params)
   }
}

const teamList = {
   teamList (params) {
      return request.post('/api/smallBonus/teamList',params)
   },
   memberList (params) {
      return request.post('/api/smallBonus/memberList',params)
   },

   //新的团队列表接口
   //用户团队信息
   teamMember (params) {
      return request.post('/api/smallBonus/teamMember', params)
   },

   //用户团队详情
   teamMemberList (params) {
      return request.post('/api/smallBonus/teamMemberList', params)
   }
}

const enterpriseList = {
   enterpriseList (params) {
      return request.post('/api/enterprise/list',params)
   },
   enterpriseAudit (params) {
      return request.post('/api/enterprise/audit',params)
   },
}

export default {
   ...userList,
   ...teamList,
   ...enterpriseList,
   ...userListNew
}