import Vue from 'vue'
import VueRouter from 'vue-router'
import np from 'nprogress'
import 'nprogress/nprogress.css'
import Home from '@/views/home'
import store from './../store'
import { chartManageRoute } from './chartsManage'
import { marketManageRoute } from './marketManage'
import { shopkeeperManageRoute } from './shopkeeperManage'
import { integratedManageRoute } from './integratedManage'
import { lecturerManageRoute } from './lecturerManage'
import { operationManageRoute } from './operationManage'
import { authRoute } from './auth'
np.configure({ showSpinner: false })
Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    component: Home,
    redirect: '/marketManage/orderList',
    // redirect:'/platformInfo',
    children: [
      /* 
        第一期不做平台概况，先隐藏入口
      */
      // {
      //   path:'/platformInfo',
      //   name:'PlatformInfo',
      //   // hidden: true,//设为true不在菜单显示
      //   component:() => import('@/views/platformInfo'),
      //   meta:{title:'平台概况',keepAlive:true,icon:'home',auth:''},
      // },
      ...chartManageRoute,
      ...marketManageRoute,
      ...shopkeeperManageRoute,
      ...integratedManageRoute,
      ...lecturerManageRoute,
      ...operationManageRoute,
      ...authRoute
    ]
  },

]

const router = new VueRouter({
  routes
})





router.beforeEach((to, from, next) => {
  np.start()
  let auth
  if (to.meta && to.meta.auth) {
    auth = to.meta.auth
  }
  if (!auth) return next()
  if (!store.state.permissionList.includes(auth)) return next({ name: 'NoAuth' })
  next()
})

router.afterEach((to) => {
  np.done()
  store.commit('setOpenPageList', to)
})

export default router
