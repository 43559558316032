import { pmsRequset } from "./request";


export default {
   // 用户信息
   loginUserInfo () {
      return pmsRequset.post('/api/auth/login-user-info')
   },

   // 用户权限
   userPermission () {
      return pmsRequset.post('/api/auth/permissions')
   },

   // 登出
   logout () {
      return pmsRequset.post('/api/auth/logout')
   }
}