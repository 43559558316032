import { mallRequset } from './request'
import { rewardRequest } from './request'
const charts = {
   // 获取当前登录用户的权限
  getRe() {
    const params = {
      gok: 1
    }
    return rewardRequest.get('/manage/reward/list?query=', params)
  },
  // *** 权益后端，获取存量用户统计
  getUserStock(params) {
    const {period, province, city, start_time, end_time} = params
    const p = province? `&province=${province}`: ''
    const c = city? `&city=${city}`: ''
    return rewardRequest.get(`/statistics/user/stock?period=${period}${p}${c}&start_time=${start_time}&end_time=${end_time}`)
  },
  // *** 权益后端，获取增量
  getUserIncrement(params) {
    const {period, province, city, start_time, end_time} = params
    const p = province? `&province=${province}`: ''
    const c = city? `&city=${city}`: ''
    return rewardRequest.get(`/statistics/user/increment?period=${period}${p}${c}&start_time=${start_time}&end_time=${end_time}`)
  },
  // 获取当前登录用户的权限
  getUserAuthInfo() {
    return mallRequset.post('/mall/adminGateway', { function: 'bbs.stat.authData' })
  },
  // 获取GMV  /stat/gmv
  getGmvList(params) {
    return mallRequset.post('/mall/adminGateway', { ...params, function: 'bbs.stat.gmvList' })
  },
  // 获取GMV  /stat/gmv
  getGmv() {
    return mallRequset.post('/mall/adminGateway', { function: 'bbs.stat.gmv' })
  },
  // 获取GMV  /stat/gmv
  getUV(params) {
    return mallRequset.post('/mall/adminGateway', { ...params, function: 'bbs.stat.uv' })
  },
  // 店铺排名
  getShopRank(params) {
    return mallRequset.post('/mall/adminGateway', { ...params, function: 'bbs.rank.shop' })
  },
  // 区域排名(省)
  getAreaRank(params) {
    return mallRequset.post('/mall/adminGateway', { ...params, function: 'bbs.rank.province' })
  },
  // 单品排名
  getGoodRank(params) {
    return mallRequset.post('/mall/adminGateway', { ...params, function: 'bbs.rank.item' })
  },
  // 区域单品UV价值排名
  getGoodUvRank(params) {
    return mallRequset.post('/mall/adminGateway', { ...params, function: 'bbs.rank.itemUvValue' })
  },
  // 区域排名(市)
  getCityRank (params) {
    return mallRequset.post('/mall/adminGateway', { ...params, function: 'bbs.rank.city' })
  },
  // 区域排名(区)
  getDistrictRank (params) {
    return mallRequset.post('/mall/adminGateway', { ...params, function: 'bbs.rank.district' })
  }
}

export default {
  ...charts,
}